@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-body-content {
  display: contents;
}

.lia-card .lia-card-grid.lia-card-grid {
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 20px;

  &.lia-card-grid-left {
    grid-template-areas:
      'icon body'
      'icon footer';

    .lia-body-content {
      text-align: left;
    }
    .lia-counts {
      justify-content: start;
    }
    .lia-avatar {
      margin-right: 15px;
    }
  }
  &.lia-card-grid-right {
    grid-template-areas:
      'body icon'
      'footer icon';

    .lia-body-content {
      text-align: right;
    }
    .lia-counts {
      justify-content: end;
    }
    .lia-avatar {
      margin-left: 15px;
    }
  }

  .lia-avatar {
    grid-area: icon;
    align-self: start;
    margin-bottom: 0;
  }

  .lia-body-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-self: start;
    grid-area: body;

    .lia-node-title {
      display: block;
    }
  }

  .lia-card-footer {
    grid-area: footer;
  }
}

.lia-show-owner-list-text {
  color: var(--lia-bs-link-color);
  cursor: pointer;
}

.lia-node-drawer-icon.lia-is-open {
  transform: rotate(180deg);
}

.lia-card-footer {
  gap: 10px;
  position: relative;
  min-height: 50px;
  border-radius: 0 0 var(--lia-panel-border-radius) var(--lia-panel-border-radius);
}

.lia-toggle {
  transition: background-color var(--lia-timing-normal) var(--lia-timing-function);
  &:hover,
  &:focus-visible {
    background-color: var(--lia-bs-gray-300);
  }
  &:focus {
    box-shadow: none;
  }
}

.lia-divider {
  margin-top: auto;
  content: '';
}

.lia-counts {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.lia-node-title {
  font-size: var(--lia-bs-font-size-base);
}

.lia-avatar {
  height: var(--lia-icon-size-160);
  width: var(--lia-icon-size-160);

  &-sm {
    height: var(--lia-icon-size-80);
    width: var(--lia-icon-size-80);
  }
  &-xs {
    height: var(--lia-icon-size-50);
    width: var(--lia-icon-size-50);
  }
}
