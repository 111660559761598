.lia-node {
  &-drawer {
    grid-column: 1 / -1;
    padding: 30px;
    box-shadow: var(--lia-box-shadow-xs);
  }

  &-children.lia-node-children {
    background-color: var(--lia-bs-body-bg);
    &:hover {
      outline: 1px solid var(--lia-bs-gray-300);
    }
  }

  &-pager {
    grid-area: pager;
    margin: 25px 0 0;
    text-align: center;
  }

  &-title {
    font-size: var(--lia-bs-font-size-base);
  }
}
